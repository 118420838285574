



















import { defineComponent, computed } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';

import NavImage from '~/components/molecules/NavImage/NavImage.vue';
import {
  NavImageInterface
} from '~/components/molecules/NavImage/NavImageTypes';

export default defineComponent({
  name: 'NavImageGrid',
  components: {
    NavImage
  },
  props: {
    /** TITLE for navImageGrid. */
    title: {
      type: String,
      required: true
    },
    /** ARRAY of navImage objects. */
    navImageList: {
      type: Array as PropType<NavImageInterface[]>,
      required: true,
      validator: (navImageList: NavImageInterface[]) => {
        return navImageList.length > 0;
      }
    }
  },
  setup(props) {
    const navImageItemPerRow = computed(() => {
      return props.navImageList.length > 6 ? 'grid-4' : 'grid-' + props.navImageList.length;
    });
    return { navImageItemPerRow };
  }
});
