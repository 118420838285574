

























import { computed, defineComponent } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import { useI18n } from '~/composables/useI18n';
import Button from '~/components/atoms/Buttons/Button/Button.vue';
import { ButtonInterface } from '~/components/atoms/Buttons/Button/ButtonTypes';
import Link from '~/components/atoms/Link/Link.vue';
import { LinkInterface } from '~/components/atoms/Link/LinkTypes';
import DisplayImage from '~/components/atoms/DisplayImage/DisplayImage.vue';

export default defineComponent({
  name: 'NavImage',
  components: {
    DisplayImage,
    Button,
    Link
  },
  props: {
    /** The specifications for the IMAGE */
    image: {
      type: String,
      required: true
    },
    /** The spec for the BUTTON */
    button: {
      type: Object as PropType<ButtonInterface>,
      required: true
    },
    /** The text for the LINK */
    link: {
      type: Object as PropType<LinkInterface>,
      default: () => {
        return null;
      }
    },
    /** Alternate text for the image */
    altImageText: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const { buildLanguagePrefixedUrlBasedOnActiveLanguage } = useI18n();
    const localizedLink = computed(() =>
      buildLanguagePrefixedUrlBasedOnActiveLanguage(props.button?.url)
    );

    return {
      localizedLink
    };
  }
});
