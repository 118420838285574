







import { defineComponent, computed } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';

import { NavImageInterface } from '~/components/molecules/NavImage/NavImageTypes';
import NavImageGrid from '~/components/organisms/NavImageGrid/NavImageGrid.vue';
import { CmsImageResponse } from '~/types/cms/CmsImageResponse';
import cmsImageToUrl from '@/helpers/cms/cmsImageToUrl';

type NavImageInterfaceCms = NavImageInterface & {
  image: CmsImageResponse;
};

export default defineComponent({
  name: 'NavImageGridCms',
  components: {
    NavImageGrid
  },
  props: {
    /** TITLE for navImageGrid. */
    title: {
      type: String,
      required: true
    },
    /** ARRAY of navImage objects. */
    navImageList: {
      type: Array as PropType<NavImageInterfaceCms[]>,
      required: true,
      validator: (navImageList: NavImageInterfaceCms[]) => {
        if (navImageList.length === 0) {
          return false;
        }

        return navImageList.every((navImage) => {
          return navImage.image && navImage.button && navImage.altImageText;
        });
      }
    }
  },
  setup(props) {
    const navImageListWithUrls = computed(() =>
      props.navImageList.map((navImage) => ({
        ...navImage,
        image: navImage.image ? cmsImageToUrl(navImage.image) : ''
      }))
    );
    return { navImageListWithUrls };
  }
});
